// keeps footer sticky to bottom and header sticky to top
.host {
  display: flex;
  height: 100vh; // avoid using min-height here for IE compatibility
  flex-direction: column;

  footer,
  div.header {
    flex-shrink: 0;
  }
  footer {
    padding: 1em 4em;
  }
}
