.cookiesInfoContainer {
  table,
  th,
  td {
    border: 1px solid black;
    padding: 1em;

    &:nth-child(3) {
      width: 50%;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.privacyContainer {
  table,
  th,
  td {
    border: 1px solid black;
  }
  .list {
    list-style: none;
  }
  .textCenter {
    text-align: center;
  }
}
