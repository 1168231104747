.confirmation-modal-container {
  width: 33.75rem;

  .ap-modal-footer {
    padding-top: 0.5rem;
    margin-top: -0.5rem;
    min-height: 4rem;
  }

  .ap-modal-body {
    min-height: 5.75rem;
  }
}
