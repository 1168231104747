.host-flyout-modal {
  .ap-modal-body {
    overflow: unset !important;
  }
}

.alert-container {
  .ap-notifications.ap-notifications-static .ap-notification {
    width: auto;
  }
}

.flyout-body-container {
  fieldset {
    font-weight: 500;
  }

  .sign-in-button {
    width: 100%;
  }
}
