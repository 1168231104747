.host {
  display: flex;
  height: 100vh;
  flex-direction: column;

  footer,
  div.header {
    flex-shrink: 0;
  }

  footer {
    padding: 14px 46px 14px 40px;
  }
}
