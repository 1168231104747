.header.ap-header.ap-header-responsive-default.solid {
  grid-template-columns: auto 1fr auto;
  z-index: 201;

  .ap-header-content {
    position: relative;
    width: 100%;

    .widget-slot {
      display: flex;
      width: 100%;
    }
  }

  .ap-header-options-item {
    border-radius: 2rem;
  }
}
