.privacyContainer {
  table,
  th,
  td {
    border: 1px solid black;
  }
  .list {
    list-style: none;
  }
}
